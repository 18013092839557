/* #################################################################################################### */
/* #################################################################################################### STYLESHEETS */
/* #################################################################################################### */
import './scss/vendors/bootstrap.scss'; // ref: https://getbootstrap.com/docs/5.3/customize/sass/#importing
// import './scss/vendors/swiper.scss'; // ref: https://swiperjs.com/get-started#installation
//import 'select2/src/scss/core.scss';
import "@fancyapps/ui/src/Fancybox/Fancybox.scss";
import 'aos/src/sass/aos.scss';
import './scss/vendors/hamburgers.scss'; // ref: https://jonsuh.com/hamburgers/#sass
import './scss/fonts.scss';
import './scss/forms.scss';
import './scss/style.scss';

/* #################################################################################################### */
/* #################################################################################################### SCRIPTS */
/* #################################################################################################### */

/* ##################################################### jquery*/
import $ from 'jquery';
require('jquery.easing')
window.jQuery = $;
window.$ = $;

/* ##################################################### AOS*/
import AOS from 'aos';

/* ##################################################### imagesloaded*/
//import ImagesLoaded  from 'imagesloaded';
const imagesload = require('imagesloaded');

/* ##################################################### fancyapps*/
import { Fancybox } from "@fancyapps/ui";

/* ##################################################### cleave*/
import { Cleave } from 'cleave.js';
require('cleave.js/dist/addons/cleave-phone.it');
//import 'cleave.js/dist/addons/cleave-phone.it'
//import path from '/_common/prefix.json'

/* ##################################################### select2*/
//import { select2 } from 'select2';
//require('select2/dist/js/i18n/it.js');

/* ##################################################### bootstrap*/
// import * as bootstrap from 'bootstrap'; // ref: https://getbootstrap.com/docs/5.3/getting-started/webpack/#import-bootstrap (Point 3)
// or, specify which plugins you need:
//import { Tooltip, Toast, Popover } from 'bootstrap';

/* ##################################################### swiper*/
//import Swiper, { Navigation, Pagination } from 'swiper'; // ref: https://swiperjs.com/get-started#install-from-npm

/* ##################################################### fontawesome*/
import { library, dom } from '@fortawesome/fontawesome-svg-core'; // ref: https://fontawesome.com/docs/apis/javascript/import-icons#package-names

//---single icons
//import { faCamera as faCameraSolid } from '@fortawesome/pro-solid-svg-icons';
//import { faCamera as faCameraRegular } from '@fortawesome/pro-regular-svg-icons';
//import { faCamera as faCameraLight } from '@fortawesome/pro-light-svg-icons';
//library.add(faCameraSolid);
//library.add(faCameraRegular);
//library.add(faCameraLight);

//---all icons by style
//import { fab } from '@fortawesome/free-brands-svg-icons';
//import { fas } from '@fortawesome/pro-solid-svg-icons';
//import { far } from '@fortawesome/pro-regular-svg-icons';
//import { fal } from '@fortawesome/pro-light-svg-icons';
//import { fat } from '@fortawesome/pro-thin-svg-icons';
//import { fad } from '@fortawesome/pro-duotone-svg-icons';
//import { fass } from '@fortawesome/sharp-solid-svg-icons';
//library.add(fab, fas, far, fal, fat, fad, fass);

import { fal } from '@fortawesome/pro-light-svg-icons';
library.add(fal);
dom.watch();

/* #################################################################################################### */
/* #################################################################################################### FUNCTIONS */
/* #################################################################################################### */

/* Init Functions on DOMContentLoaded */
const ready = (callback) => {
    if (document.readyState != "loading") callback()
    else document.addEventListener("DOMContentLoaded", callback)
};


const isDebug = process.env.NODE_ENV !== 'production';

/* Variables and functions */
var deviceWidth;
var deviceHeight;
let isAnimating = false;
let urlHash;

// webpack version
const initWebpackVersion = () => {
    isDebug && console.log(`%c${DESCRIPTION}`, `color: #9ac456; font-size: 18px; font-weight: 700;`, `Version ${VERSION} - Development Environment`);
};

// flexfont (add .flexFont to container per scale child font-size)
//const flexFont = () => {
//    $('.flexFont').each(function () {
//        var relFontsize = $(this).width() * 0.2;
//        $(this).css('font-size', relFontsize + 'px').css('line-height', relFontsize + 'px')
//    });
//};

// sendjson
//const sendjson = (dati, url, type, onSuccess) => {
//    var response = "";
//    $.ajax({
//        url: url,
//        type: type,
//        cache: false,
//        data: dati,
//        contentType: 'application/json; charset=utf-8',
//        dataType: 'json',
//        success: function (r) {
//            if (onSuccess !== undefined && onSuccess != null)
//                onSuccess(r);
//        }
//    });
//};

// counter textarea characters
//const countCharacters = (elm, max) => {
//    //console.log("elm= " + elm);
//    $(elm).on('change keyup paste', function () {
//        var txt = $(this).val();
//        if (txt.length > max) {
//            $(this).val(txt.substring(0, max));
//        }
//        var count = $('#counterText');
//        if (count) {
//            var remain = max - txt.length;
//            remain = (remain < 0) ? 0 : remain;
//            count.text(remain + '/' + max);
//        }
//    });
//}

// scrollToElm 
const scrollToElm = (n) => {
    var hh;
    if ($('header').hasClass('sticky-top') || $('header').hasClass('position-fixed')) {
        hh = $('header').outerHeight();
    } else {
        hh = 0;
    }
    var elm = $('*[data-anchor=' + n + ']').offset().top - hh;
    $('html, body').animate({
        scrollTop: elm + 1
    }, 500, 'easeInOutQuart', function () {
        setFocusOnElement(document.querySelector('*[data-anchor=' + n + ']'));
    });

    $('header .hamburger').removeClass('is-active');
    $('header .item-nav').removeClass('is-active');
    $('[aria-expanded]').each(function (i, el) {
        $(this).attr('aria-expanded', 'false');
    });
}

// scrollToHash
function scrollToHash(hash) {
    if (hash) {
        if ($('*[data-anchor=' + hash + ']').length > 0) {
            var hh;
            if ($('header').hasClass('sticky-top') || $('header').hasClass('position-fixed')) {
                hh = $('header').outerHeight();
            } else {
                hh = 0;
            }
            var elm = $('*[data-anchor=' + hash + ']').offset().top - hh;
            $('html, body').animate({
                scrollTop: elm + 1
            }, 500, 'easeInOutQuart', function () {
                setFocusOnElement(document.querySelector('*[data-anchor=' + hash + ']'));
            });

            $('header .hamburger').removeClass('is-active');
            $('header .item-nav').removeClass('is-active');
            $('[aria-expanded]').each(function (i, el) {
                $(this).attr('aria-expanded', 'false');
            });
        }
    }
}

// scrollToFirstErr
const scrollToFirstErr = (n) => {
    var hh;
    if ($('header').hasClass('sticky-top') || $('header').hasClass('position-fixed')) {
        hh = $('header').outerHeight();
    } else {
        hh = 0;
    }
    var elm = $('.error-msg:visible:first');
    var par = elm.parent()[0].offsetTop - hh;
    $('html, body').animate({
        scrollTop: par - 10
    }, 500, 'easeInOutQuart', function () {

    });
}

/*setFocusOnElement*/
function setFocusOnElement(target) {
    var focusable = target.querySelectorAll('input, select, textarea, button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])');
    var firstFocusable = focusable[0];
    //console.log(firstFocusable);
    if (firstFocusable) {
        firstFocusable.focus();
    }
}

// fancybox 4
const showLoading = (cl) => {
    var tmp = '<div class="fancybox__container is-animated ' + cl + ' role="dialog" tabindex="-1" aria-modal="true" aria-hidden="false" aria-label="" id="fancybox-loading"><div class="fancybox__backdrop"></div><div class="fancybox__spinner is-draggable"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="25 25 50 50" tabindex="-1"><circle cx="50" cy="50" r="20"></circle></svg></div></div>';
    $('body').append(tmp);
    $('#fancybox-loading').fadeIn();
}
const hideLoading = () => {
    $('#fancybox-loading').fadeOut(500, function () {
        $('#fancybox-loading').remove();
    });
}
const closeFancybox = () => {
    Fancybox.close();
}
const popMessage = (n, cl) => {
    Fancybox.show([
        {
            src: "<p class='fs-x-small m-0'>" + n + "</p>",
            type: "html"
        }
    ],
        {
            mainClass: cl,
            dragToClose: false,
            closeButton: "inside",
        }
    );
}
const popMessageModal = (n, cl) => {
    Fancybox.show([
        {
            src: "<p class='fs-x-small m-0'>" + n + "</p>",
            type: "html",
            click: function () { return false; }
        }
    ],
        {
            mainClass: cl,
            closeButton: '',
            dragToClose: false,
            keyboard: false
        }
    );
}
const popInline = (n, cl) => {
    Fancybox.show([
        {
            src: n,
            type: "inline",
            click: function () { return false; }
        }
    ],
        {
            mainClass: cl,
            dragToClose: false,
            closeButton: "inside",
        }
    );
}

// Asp validator
const ValidatorUpdateDisplay = (val) => {
    if (typeof (val.display) == "string") {
        if (val.display == "None") {
            return;
        }
        if (val.display == "Dynamic") {
            val.style.display = val.isvalid ? "none" : "block";
            ValidatorInput(val);
            return;
        }
    }
    if ((navigator.userAgent.indexOf("Mac") > -1) &&
        (navigator.userAgent.indexOf("MSIE") > -1)) {
        val.style.display = "inline";
    }
    val.style.visibility = val.isvalid ? "hidden" : "visible";
    ValidatorInput(val);
}

const ValidatorInput = (val) => {
    const elements = Array.from(val.parentNode.querySelectorAll('input, select'));
    const invalidClass = 'invalid';

    if (val.isvalid) {
        val.classList.remove(invalidClass);
        $('#' + val.id).parents('.wrap-checkbox').removeClass(invalidClass);
        $('#' + val.id).parents('.wrap-radio').removeClass(invalidClass);
    } else {
        val.classList.add(invalidClass);
        $('#' + val.id).parents('.wrap-checkbox').addClass(invalidClass);
        $('#' + val.id).parents('.wrap-radio').addClass(invalidClass);
    }

    const feedbacks = Array.from(val.parentNode?.querySelectorAll('.error-msg'));
    const isInputInvalid = feedbacks.find(item => item.classList.contains(invalidClass));
    elements.forEach(elem => {
        isInputInvalid ? elem?.classList.add(invalidClass) : elem?.classList.remove(invalidClass);
        isInputInvalid ? elem?.setAttribute('aria-invalid', true) : elem?.removeAttribute('aria-invalid')
    });
}

const IsValidationGroupMatch = (control, validationGroup) => {
    if ((typeof (validationGroup) == "undefined") || (validationGroup == null)) {
        return true;
    }
    let controlGroup = "";
    if (typeof (control.validationGroup) == "string") {
        controlGroup = control.validationGroup;
    }
    return (controlGroup == validationGroup);
};

const ValidationSummaryOnSubmit = (validationGroup, message = null, target = null, success = false) => {

    const alertOffset = 2 //rem

    if (typeof (Page_ValidationSummaries) === "undefined")
        return;

    let summary, sums, s;

    for (sums = 0; sums < Page_ValidationSummaries.length; sums++) {
        summary = Page_ValidationSummaries[sums];
        summary.style.display = "none";
        if (!Page_IsValid && IsValidationGroupMatch(summary, validationGroup)) {
            var i;
            if (summary.showsummary !== "False") {
                summary.style.display = "";
                if (typeof (summary.displaymode) !== "string") {
                    summary.displaymode = "BulletList";
                }
                switch (summary.displaymode) {
                    case "List":
                        headerSep = "<br />";
                        first = "";
                        pre = "";
                        post = "<br />";
                        end = "";
                        break;
                    case "BulletList":
                    default:
                        headerSep = "";
                        first = "<ul>";
                        pre = "<li>";
                        post = "</li>";
                        end = "</ul>";
                        break;
                    case "SingleParagraph":
                        headerSep = " ";
                        first = "";
                        pre = "";
                        post = " ";
                        end = "<br />";
                        break;
                }
                s = "";
                if (typeof (summary.headertext) === "string") {
                    s += summary.headertext + headerSep;
                }
                s += first;
                for (i = 0; i < Page_Validators.length; i++) {
                    if (!Page_Validators[i].isvalid && typeof (Page_Validators[i].errormessage) == "string") {
                        s += pre + Page_Validators[i].errormessage + post;
                    }
                }
                s += end;
                summary.innerHTML = s;
            }
            if (summary.showmessagebox === "True") {
                s = '';
                if (typeof (summary.headertext) === "string") {
                    s += summary.headertext + "<br />";
                }
                var lastValIndex = Page_Validators.length - 1;
                for (i = 0; i <= lastValIndex; i++) {
                    if (!Page_Validators[i].isvalid && typeof (Page_Validators[i].errormessage) === "string") {
                        switch (summary.displaymode) {
                            case "List":
                                s += Page_Validators[i].errormessage;
                                if (i < lastValIndex) {
                                    s += "<br />";
                                }
                                break;
                            case "BulletList":
                            default:
                                s += "- " + Page_Validators[i].errormessage;
                                if (i < lastValIndex) {
                                    s += "<br />";
                                }
                                break;
                            case "SingleParagraph":
                                s += Page_Validators[i].errormessage + " ";
                                break;
                        }
                    }
                }
                s += "";
                scrollToFirstErr();
                popMessage(s);
                return;
            } else {
                scrollToFirstErr();
                return;
            }
        }
    }
}


// Init Swiper
/*const initSwiperExample = () => {
    new Swiper('.SwiperExample', {
        modules: [Navigation, Pagination],
        navigation: {
            prevEl: '.SwiperExample .swiper-button-prev',
            nextEl: '.SwiperExample .swiper-button-next'
        },
        pagination: {
            el: '.SwiperExample .swiper-pagination',
            clickable:true
        }
    });
}*/

// Height 100% of viewport (trick for bars)
let windowWidth = 0;
const appHeight = () => {
    //const currentWindowWidth = window.innerWidth;
    //if (currentWindowWidth !== windowWidth) {
    //    windowWidth = currentWindowWidth;
    //    const doc = document.documentElement;
    //    doc.style.setProperty('--vph-100', `${window.innerHeight}px`);
    //}
    const doc = document.documentElement;
    doc.style.setProperty('--vph-100', `${window.innerHeight}px`);
};
appHeight();
window.addEventListener('resize', appHeight);

//AOS
const initAOS = () => {
    AOS.init({
        // Global settings:
        disable: 'mobile', //false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 1000, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

    });
};



$(window).resize(function () {
    deviceWidth = $(window).width();
    deviceHeight = $(window).height();
    //flexFont();
});

$(window).on('hashchange', function () {
    urlHash = location.hash.replace(/^#/, '');
    scrollToHash(urlHash);
});

/* Ready callback function */
ready(() => {
    initWebpackVersion();

    deviceWidth = $(window).width();
    deviceHeight = $(window).height();

    

    //hash
    urlHash = location.hash;
    if (urlHash != "") {
        urlHash = urlHash.substr(1, urlHash.length);
        setTimeout(function () {
            scrollToHash(urlHash);
        }, 1000);
    }

    //nav
    //$('nav a').click(function () {
    //    var hrefvalue = $(this).attr('href').split('#')[1]
    //    var hashvalue = window.location.hash.split('#')[1];
    //    if (hrefvalue == hashvalue) {
    //        scrollToHash(hrefvalue);
    //    }
    //});

    //imagesload
    if (imagesload) {
        var preload_i = 0;
        var perc;
        $('body').imagesLoaded().always(function (instance) {
            setTimeout(function () {
                hideLoading();
                initAOS();
            }, 1000);
        }).progress(function (instance, image) {
            preload_i++;
            perc = Math.round((preload_i * 100) / instance.images.length);
        });
    }

    // hamburger
    //$('header .hamburger').click(function () {
    //    $(this).toggleClass('is-active');
    //    $('.item-nav').toggleClass('is-active');
    //});

    // Accessibility: aria-expanded true/false on click
    //$('[aria-expanded]').each(function (i, el) {
    //    $(el).on('click', function () {
    //        $(this).attr('aria-expanded', function (index, attr) {
    //            return attr == "false" ? "true" : "false";
    //        });
    //    });
    //});
	
	$('.form-check').each(function () {
		$('input', this).unwrap('span');
	});

    //select
    $('.wrap-select select').each(function () {
        if (!$.trim(this.value).length) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });
    $('.wrap-select select').change(function () {
        if (!$.trim(this.value).length) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });

    //show-hide password
    //$('.showhide-pwd a').click(function () {
    //    var rel = $(this).attr('rel');
    //    if ($('#' + rel).prop('type') == 'password') {
    //        $('#' + rel).prop('type', 'text');
    //        $('.n', this).addClass('d-none');
    //        $('.y', this).removeClass('d-none');
    //    } else {
    //        $('#' + rel).prop('type', 'password');
    //        $('.n', this).removeClass('d-none');
    //        $('.y', this).addClass('d-none');
    //    }
    //});

    $('section.sticky-top').css('top', ($('header').outerHeight() + 10) + 'px');

    //flexFont();

    /*if ($('section.SwiperExample').length > 0) {
        initSwiperExample();
    }*/

    //$('#txtCodice').on('keyup', function () {
    //    var codelength = this.value.length;
    //    var maxlength = 2; // this.getAttribute('maxlength');
    //    if (codelength == maxlength) {
    //        setTimeout(function () {
    //            scrollToElm('registrazione');
    //        }, 300);
    //    }
    //});
});

//***expose functions
window.ValidatorUpdateDisplay = ValidatorUpdateDisplay;
window.ValidationSummaryOnSubmit = ValidationSummaryOnSubmit;
//window.sendjson = sendjson;
//window.countCharacters = countCharacters;
window.scrollToElm = scrollToElm;
window.scrollToHash = scrollToHash;
window.scrollToFirstErr = scrollToFirstErr;
window.showLoading = showLoading;
window.hideLoading = hideLoading;
window.closeFancybox = closeFancybox;
window.popMessage = popMessage;
window.popMessageModal = popMessageModal;
window.popInline = popInline;
//window.flexFont = flexFont;
window.initAOS = initAOS;
